import '@/css/app.pcss';

import Glide from '@glidejs/glide';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => { 
  /* Do things after DOM has fully loaded */ 
	
	//--------------------------------------------------------------- Sticky Header
	
	// Debounce limits how often the scroll function fires
	function debounce(method, delay) {
		clearTimeout(method._tId);
		method._tId= setTimeout(function(){
			method();
		}, delay);
	}
	
	window.onscroll = function() {
		debounce(scrollFunction, 100);
	};
	
	const stickyHeader = document.getElementById('site-header');
	
	let oldValue = 0;
	let newValue = 0;
	
	function scrollFunction() {
		
		newValue = window.pageYOffset;
		if (oldValue < newValue) {
			
			//console.log("Scrolling Down");
			
			if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
					  
				stickyHeader.classList.add("sticky-header");
			
			} else {
			  
				stickyHeader.classList.remove("sticky-header");
			
			}
					
			
		} else if (oldValue > newValue) {
			
			//console.log("Scrolling Up");
			
			stickyHeader.classList.remove("sticky-header");
			
		}
		
		oldValue = newValue;
		  
		
	}
	
	//--------------------------------------------------------------- Navigation

	
	const toggleMenu = document.querySelector('.navigation button');
	const menu = document.querySelector('.navigation ul');
	
	
	
	/* When you click on the button, we call a function that will get the current value of the "aria-expanded" attribute, and invert it. It will do the same for the "hidden" attribute on the menu. When the menu is visible, we'll focus the first link so it gets properly announced. */
	
	toggleMenu.addEventListener('click', function () {
		// The JSON.parse function helps us convert the attribute from a string to a real boolean
		const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
		
		// whatever the value of open is set it to the opposite
		toggleMenu.setAttribute('aria-expanded', !open);
		
		// whatever the value of the html element 'hidden' on menu is set it to the opposite
		menu.hidden = !menu.hidden;
		
		//var hamburger = document.querySelector(".hamburger");
		toggleMenu.classList.toggle("is-active");
		
		// if the menu isn't hidden focus on the first menu link
		if (!menu.hidden) {
			menu.querySelector('a').focus();
		}
		
	});
	
	
	// Drop down menus on navigation
	
	var menuItems = document.querySelectorAll('li.has-submenu');
	Array.prototype.forEach.call(menuItems, function(el, i){
		var activatingA = el.querySelector('a');
		var btn = '<button class="submenu-button-icon"><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
		activatingA.insertAdjacentHTML('afterend', btn);
	
		el.querySelector('button').addEventListener("click",  function(event){
			if (this.parentNode.className == "has-submenu") {
				this.parentNode.className = "has-submenu open";
				this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
				this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
			} else {
				this.parentNode.className = "has-submenu";
				this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
				this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
			}
			event.preventDefault();
		});
	});
	
	
	
	//--------------------------------------------------------------- Accordions
	
	
	  
	document.querySelectorAll('.accordion-content-toggle').forEach(item => {
	
		item.addEventListener('click', event => {
			
			event.preventDefault();
			event.stopPropagation();
			  
			let targetLink = item.getAttribute('data-toggle');
			
			let targetSection = document.getElementById(targetLink);
			
			toggleAccordian(targetSection);
			
			item.classList.toggle("accordion-content-toggle-expanded");
			
			
		})
	
	})
	
	
	
	function toggleAccordian(targetSection) {
			
		targetSection.classList.toggle("accordion-content-visible");
		
		
	}
	
	//--------------------------------------------------------------- Carousels
	  
	  
	  
	
	// Gallery Carousel
		
	// Check to see if any elements with this class name exist
	
	let galleryCarousels = document.getElementsByClassName("gallery-carousel");
	
	if (galleryCarousels.length) {
	
		new Glide('.gallery-carousel',
		
		{
			type: 'carousel',
			startAt: 0,
			perView: 2,
			gap: 15,
			peek: 290,
			breakpoints: {
				1680: {
					perView: 2
				},
				1560: {
					perView: 2
				},
				1280: {
					perView: 2
				},
				1024: {
					perView: 2,
					peek: 50
				},
				768: {
					perView: 1,
					peek: 30
				}
			}
		
		}).mount();

	} 
  
	// -------------------------------------------------------- Accessible modals
	
	
	// Open modals
	document.querySelectorAll('.modal-trigger').forEach(item => {
	
		item.addEventListener('click', event => {
		
			let targetLink = item.getAttribute('data-modal');
			
			let targetModal = document.getElementById(targetLink);
			
			openModal(targetModal);
			
			return false;
		
		})
	
	})
  
	  
	function openModal(targetModal) {
	
		targetModal.classList.add("modal-visible");
	
	}
	  
	  // Close modals
	  
	function closeModals() {
	
		let targetModals = document.querySelectorAll('.modal');
		
		targetModals.forEach(function(el) {
			  el.classList.remove("modal-visible");
		});
		
		
	
	}
	
	document.querySelectorAll('.modal-close-trigger').forEach(item => {
	
	item.addEventListener('click', event => {
	  
		  closeModals();
	  
		  return false;
	  
	})
	
	})
	
	// close modals with esc key
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			  closeModals();
		}
	})
	
	
	
	
	
	
	
	
	
}); // END DOC READY

// Vanilla version of FitVids - requires CSS (src/styles/partials/_video.scss)
(function(window, document, undefined) {
  "use strict";

  // List of Video Vendors embeds you want to support
  var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

  // Select videos
  var fitVids = document.querySelectorAll(players.join(","));

  // If there are videos on the page...
  if (fitVids.length) {
	// Loop through videos
	for (var i = 0; i < fitVids.length; i++) {
	  // Get Video Information
	  var fitVid = fitVids[i];
	  var width = fitVid.getAttribute("width");
	  var height = fitVid.getAttribute("height");
	  var aspectRatio = height / width;
	  var parentDiv = fitVid.parentNode;

	  // Wrap it in a DIV
	  var div = document.createElement("div");
	  div.className = "fitVids-wrapper";
	  div.style.paddingBottom = aspectRatio * 100 + "%";
	  parentDiv.insertBefore(div, fitVid);
	  fitVid.remove();
	  div.appendChild(fitVid);

	  // Clear height/width from fitVid
	  fitVid.removeAttribute("height");
	  fitVid.removeAttribute("width");
	}
  }
})(window, document);